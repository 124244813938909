import debitCard from "../images/debitCardProdcuts.png";
import partnershipMasterCard from "../images/partnershipMasterCard.svg";
import partnershipMasterCardArabic from "../images/partnershipMasterCardArabic.svg";
import underLines from "../images/Underlines.svg";

const images = {
	debitCard,
	partnershipMasterCard,
	partnershipMasterCardArabic,
	underLines,
};
export default images;
