import React from "react";
import images from "../../assets/images";
import { motion } from "framer-motion";

export const PageHeader = ({ miniHeader, header, text }) => {
	return (
		<section className="w-10/12 xl:w-9/12 mx-auto flex flex-col items-center justify-center text-center">
			{miniHeader && (
				<div className=" text-sm font-semibold text-primary mb-6">
					<motion.p
						initial="hidden"
						whileInView="visible"
						viewport={{ once: true }}
						transition={{ duration: 1, type: "spring", stiffness: 100 }}
						variants={{
							visible: { y: 0 },
							hidden: { y: -100 },
						}}
						className="uppercase "
					>
						{miniHeader}
					</motion.p>

					<motion.img
						initial="hidden"
						whileInView="visible"
						viewport={{ once: true }}
						transition={{ duration: 1, type: "spring", stiffness: 100 }}
						variants={{
							visible: { y: 0 },
							hidden: { y: -50 },
						}}
						src={images.underLines}
						alt=""
					/>
				</div>
			)}
			{header && (
				<motion.h2
					initial="hidden"
					whileInView="visible"
					viewport={{ once: true }}
					transition={{ duration: 1, type: "spring", stiffness: 150 }}
					variants={{
						visible: { y: 0 },
						hidden: { y: 70 },
					}}
					className="font-semibold rtl:leading-tight text-4xl md:text-5xl xl:w-8/12 mx-auto lg:leading-tight"
				>
					{header}
				</motion.h2>
			)}
			{text && (
				<motion.p
					initial="hidden"
					whileInView="visible"
					viewport={{ once: true }}
					transition={{ duration: 0.9, type: "spring", stiffness: 400 }}
					variants={{
						visible: { y: 0 },
						hidden: { y: 50 },
					}}
					className="text-xl text-text opacity-70 lg:w-8/12 mx-auto  mt-6"
				>
					{text}
				</motion.p>
			)}
		</section>
	);
};
