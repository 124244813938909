import React from "react";
import { motion } from "framer-motion";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

export const FigureImage = ({ src, className, imgClass, gatsbyImage }) => {
	const imageData = gatsbyImage && getImage(gatsbyImage.localFile);
	if (gatsbyImage) {
		return (
			<figure className={className}>
				{imageData && (
					<GatsbyImage
						image={imageData}
						imgClassName={imgClass}
						className={className}
					/>
				)}
			</figure>
		);
	}
	return (
		src && (
			<figure className={className}>
				<motion.img
					initial="hidden"
					whileInView="visible"
					viewport={{ once: true }}
					transition={{ duration: 1 }}
					variants={{
						visible: { opacity: 1 },
						hidden: { opacity: 0 },
					}}
					src={src}
					alt="Kashat"
					className={imgClass}
				/>
			</figure>
		)
	);
};
