import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { StoreLinks } from "./StoreLinks";
import { motion } from "framer-motion";
import { DescriptionSide } from "./Atomics/DescriptionSide";
// import { NewsLetter } from "./Atomics/NewsLetter";

export const Cta = ({ miniHeader, header, subHeader, lang }) => {
	return (
		<>
			<section className="bg-[#F9FAFB]">
				<div className="grid lg:grid-cols-2 gap-12 xl:gap-12  w-10/12 xl:w-9/12 mx-auto pt-12 pb-12 lg:pb-0">
					<motion.div
						initial="hidden"
						whileInView="visible"
						viewport={{ once: true }}
						transition={{ duration: 0.9 }}
						variants={{
							visible: { opacity: 1 },
							hidden: { opacity: 0.2 },
						}}
						className="flex justify-center"
					>
						<StaticImage src="../images/CTA.png" placeholder="blurred" />
					</motion.div>
					<div className="flex flex-col justify-center">
						<DescriptionSide
							miniHeader={miniHeader}
							header={header}
							subHeader={subHeader}
						/>

						<motion.div
							initial="hidden"
							whileInView="visible"
							viewport={{ once: true }}
							transition={{ duration: 1 }}
							variants={{
								visible: { y: 0, opacity: 0.9 },
								hidden: { y: 20, opacity: 0.2 },
							}}
							className="lg:ml-6 rtl:lg:mr-6"
						>
							<StoreLinks shadow />
						</motion.div>
					</div>
				</div>
			</section>
			{/* <NewsLetter lang={lang} /> */}
		</>
	);
};
