import React from "react";
import { FigureImage } from "../../Atomics/FigureImage";
import { motion } from "framer-motion";

export const InvestorsPreview = ({ investors, lang }) => {
	return (
		<section className="w-10/12 xl:w-9/12 mx-auto my-36">
			<ul className=" grid lg:grid-cols-4 gap-12 h-full">
				{investors &&
					investors.map((item, index) => {
						return <InvestorCard {...item} lang={lang} key={index} />;
					})}
			</ul>
		</section>
	);
};

const InvestorCard = ({ lang, ...item }) => {
	return (
		<motion.li
			initial="hidden"
			whileInView="visible"
			viewport={{ once: true }}
			transition={{ duration: 1, type: "spring", stiffness: 100 }}
			variants={{
				visible: { opacity: 1, y: 0 },
				hidden: { opacity: 0.4, y: 150 },
			}}
			className="flex flex-col "
		>
			<div
				className="flex items-center justify-center p-12 aspect-square bg-[#C4C4C4] bg-opacity-10 
        rounded-xl"
			>
				{item.image && <FigureImage gatsbyImage={item.image} alt="" />}{" "}
			</div>
			<div className="mb-4">
				{item.title && (
					<h3 className=" text-3xl text-text font-semibold my-4">
						{item.title}
					</h3>
				)}{" "}
				{item.text && <p className="text-text">{item.text}</p>}{" "}
			</div>
			{item.link && (
				<div className=" justify-self-end  mt-auto">
					<a
						href={item.link}
						target="_blank"
						rel="noopener noreferrer"
						className=" uppercase underline  "
					>
						{lang === "ar" ? "زيارة الموقع" : "Visit Website"}
					</a>
				</div>
			)}
		</motion.li>
	);
};
