import React from "react";
import { motion } from "framer-motion";
import classNames from "classnames";

export const BannerCard = ({
	Icon,
	header,
	text,
	children,
	bgColor,
	border,
	textClass,
}) => {
	return (
		<motion.section
			initial="hidden"
			whileInView="visible"
			viewport={{ once: true }}
			transition={{ duration: 1, type: "spring", stiffness: 75 }}
			variants={{
				visible: { opacity: 1, y: 0 },
				hidden: { opacity: 0.4, y: 250 },
			}}
			className={classNames("my-16")}
		>
			<div
				className={`py-16 md:pt-24 md:pb-36 rounded-[32px] flex flex-col items-center justify-center w-10/12 xl:w-9/12 mx-auto text-center ${
					border && " border-2"
				} `}
				style={{
					background:
						bgColor &&
						"linear-gradient(90deg, #FFE600 -21.67%, #FFDC02 21.33%, #FFD104 54.3%, #FFC10E 121.67%)",
				}}
			>
				{Icon && (
					<motion.div
						initial="hidden"
						whileInView="visible"
						viewport={{ once: true }}
						transition={{ duration: 1 }}
						variants={{
							visible: { opacity: 1 },
							hidden: { opacity: 0 },
						}}
						className="mb-10 lg:mb-20 "
					>
						{Icon}
					</motion.div>
				)}{" "}
				{header && (
					<motion.h2
						initial="hidden"
						whileInView="visible"
						viewport={{ once: true }}
						transition={{ duration: 1 }}
						variants={{
							visible: { opacity: 1 },
							hidden: { opacity: 0 },
						}}
						className="text-2xl md:text-4xl lg:text-5xl rtl:lg:text-4xl rtl:lg:leading-normal font-bold  mb-6 w-10/12  lg:w-6/12 xl:w-8/12 mx-auto"
					>
						{header}
					</motion.h2>
				)}
				{text && (
					<motion.p
						initial="hidden"
						whileInView="visible"
						viewport={{ once: true }}
						transition={{ duration: 1.6 }}
						variants={{
							visible: { opacity: 1 },
							hidden: { opacity: 0 },
						}}
						className={classNames(
							"w-10/12  lg:w-5/12 xl:w-6/12 mx-auto  text-[#464646]",
							textClass
						)}
					>
						{text}
					</motion.p>
				)}
				<motion.div
					initial="hidden"
					whileInView="visible"
					viewport={{ once: true }}
					transition={{ duration: 1.6 }}
					variants={{
						visible: { opacity: 1 },
						hidden: { opacity: 0 },
					}}
				>
					{children}
				</motion.div>
			</div>
		</motion.section>
	);
};
