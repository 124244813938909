import React from "react";
import { graphql } from "gatsby";
import Layouts from "../../components/Layouts";
import { PageHeader } from "../../components/Atomics/PageHeader";
import { Cta } from "../../components/Cta";
import { InvestorsPreview } from "../../components/Screens/Investors/InvestorsPreview";
import { BannerCard } from "../../components/Screens/Products/BannerCard";
import Seo from "../../components/Seo";

const InvestorsPageArabic = ({ data }) => {
	const page = data.page;
	return (
		<>
			<Seo title="المستثمرون" />
			<Layouts lang={"ar"} dir="rtl">
				<PageHeader
					miniHeader={page.investorsAr.investorsAr.miniHeader}
					header={page.investorsAr.investorsAr.header}
				/>
				<InvestorsPreview
					lang="ar"
					investors={page.investorsAr.investorsAr.investors}
				/>
				<BannerCard
					bgColor
					Icon={Icon}
					header={
						"اكتشف أول شركة بتوفر تمويل متناهي الصغر في الشرق الأوسط وشمال إفريقيا"
					}
					text={"	عايز تعرف أكتر عن كاشات وتأثيرها في عملية الشمول المالي؟ "}
					children={
						<>
							<a href="mailto:investors@Kashat.com.eg">
								<button className="py-5 mt-12 px-20 rounded-2xl  font-bold bg-white text-[#F6B904] relative">
									<span>تواصل معنا</span>
								</button>
							</a>
						</>
					}
				/>
				<Cta
					lang="ar"
					miniHeader="أول مقدم للخدمات المالية متناهية الصغر في الشرق الأوسط"
					header="مهمتنا تمكين الوصول الى الإمكانيات المالية الرقمية"
					subHeader="كاشات هي أول خطوة للشمول المالي للمصريين الغير متعاملين مع البنوك عن طريق توفير تمويلات انتاجية قصيرة المدى وسريعة"
				/>
			</Layouts>
		</>
	);
};
export default InvestorsPageArabic;
export const InvestorsArabicQuery = graphql`
	query InvestorsArabic {
		page: wpPage(databaseId: { eq: 378 }) {
			title
			investorsAr {
				investorsAr {
					miniHeader
					header
					investors {
						image {
							localFile {
								childImageSharp {
									gatsbyImageData(width: 100, placeholder: BLURRED)
								}
							}
						}
						title
						text
						link
					}
					banner {
						title
						text
					}
				}
			}
		}
	}
`;

const Icon = (
	<svg
		width="72"
		height="72"
		viewBox="0 0 72 72"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<rect width="72" height="72" rx="16" fill="#202020" fill-opacity="0.2" />
		<g clip-path="url(#clip0_429_5682)">
			<path
				d="M46.002 37.6666V34.3333C49.6786 34.3333 52.6686 31.3433 52.6686 27.6666C52.6686 23.9916 49.6786 21 46.002 21C42.3253 21 39.3353 23.9916 39.3353 27.6666C39.3353 29.1233 39.797 30.5066 40.6703 31.6666L38.0053 33.67C36.6953 31.93 36.002 29.8516 36.002 27.6666C36.002 22.1516 40.487 17.6666 46.002 17.6666C51.517 17.6666 56.0019 22.1516 56.0019 27.6666C56.0019 33.1816 51.517 37.6666 46.002 37.6666Z"
				fill="#12022F"
			/>
			<path
				d="M37.6686 42.6666C38.5891 42.6666 39.3353 41.9204 39.3353 40.9999C39.3353 40.0794 38.5891 39.3333 37.6686 39.3333C36.7481 39.3333 36.002 40.0794 36.002 40.9999C36.002 41.9204 36.7481 42.6666 37.6686 42.6666Z"
				fill="#12022F"
			/>
			<path
				d="M41.8359 39.3333C43.2167 39.3333 44.3359 38.214 44.3359 36.8333C44.3359 35.4525 43.2167 34.3333 41.8359 34.3333C40.4552 34.3333 39.3359 35.4525 39.3359 36.8333C39.3359 38.214 40.4552 39.3333 41.8359 39.3333Z"
				fill="#12022F"
			/>
			<path
				d="M29.3346 42.6666C25.658 42.6666 22.668 39.6766 22.668 35.9999C22.668 32.3249 25.658 29.3333 29.3346 29.3333C33.0113 29.3333 36.0013 32.3249 36.0013 35.9999C36.0013 39.6766 33.0113 42.6666 29.3346 42.6666ZM29.3346 32.6666C27.4963 32.6666 26.0013 34.1599 26.0013 35.9999C26.0013 37.8383 27.4963 39.3333 29.3346 39.3333C31.173 39.3333 32.668 37.8383 32.668 35.9999C32.668 34.1599 31.173 32.6666 29.3346 32.6666Z"
				fill="#12022F"
			/>
			<path
				d="M42.6686 55.9999H16.002V54.3333C16.002 48.4449 21.4853 44.3333 29.3353 44.3333C37.1853 44.3333 42.6686 48.4449 42.6686 54.3333V55.9999ZM19.6003 52.6666H39.072C38.0753 49.6533 34.3503 47.6666 29.337 47.6666C24.3236 47.6666 20.597 49.6533 19.6003 52.6666Z"
				fill="#12022F"
			/>
		</g>
		<defs>
			<clipPath id="clip0_429_5682">
				<rect
					width="40"
					height="40"
					fill="white"
					transform="translate(16 16)"
				/>
			</clipPath>
		</defs>
	</svg>
);
